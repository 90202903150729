import facebook from './images/facebook.png';
import twitter from './images/twitter.png';
import instagram from './images/instagram.png';
import video from './videos/Pexels-C-Technical-6153458-1.mp4';
import './App.css';

function App() {

  const toggleMenu = (e) => {
      document.querySelector('.toggle').classList.toggle('active');
      document.querySelector('.showcase').classList.toggle('active');
  }; 

  return (
    <div className="app">
        <section className="showcase">
            <header>
                <h2 className="logo">Cyberinformatics</h2>
                <div className="toggle" onClick={toggleMenu}></div>
            </header>
            <video autoPlay loop muted playsInline>
                <source src={video} type="video/mp4" />
            </video>
            <div className="overlay"></div>
            <div className="text">
                <h2>Never Stop</h2>
                <h3>Exploring The World</h3>
                {/*<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eos debitis eveniet non modi dolorem tempora repellendus natus quia, recusandae earum quam dicta reprehenderit, ab inventore omnis iste impedit nesciunt. At!</p> -->
                <a href="#">Explore</a>*/}
            </div>
            {/*<ul className="social">
            <li><a href="#"><img src={facebook} alt="" /></a></li>
            <li><a href="#"><img src={twitter} alt="" /></a></li>
            <li><a href="#"><img src={instagram} alt="" /></a></li>
            </ul>*/}
        </section>

        <div className="menu">
            <ul>
                <li><a href="https://platform.cyberinformatics.de">Login</a></li>
            </ul>
        </div>
    </div>
  );
}

export default App;
